/*
    Database driven index of all people
*/

import React, {useState, useEffect} from 'react';
import {Chip} from '@material-ui/core'
import {Add} from '@material-ui/icons'
import {Link as RouterLink} from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MemberCard from '../Components/MemberCard';
import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';
import SearchIcon from '@material-ui/icons/Search';
import {IconButton, InputBase, ButtonGroup, Button, Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {filter, includes, some, has, sortBy} from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  searchWrapper: {
    alignItems: 'stretch',
    display: 'flex'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    backgroundColor:'#dddddd',
    paddingLeft:10,
    borderTopLeftRadius:6,
    borderBottomLeftRadius:6
  },
  iconButton: {
    padding: 5,
    paddingBottom:3,
    backgroundColor:'#dddddd',
    borderRadius:0,
    borderTopRightRadius:6,
    borderBottomRightRadius:6
  },
  divider: {
    height: 28,
    margin: 4,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Home() {
  const classes = useStyles();
  //Set up initial variables
      const [members, setMembers] = useState([])
      const [filteredMembers, setFilteredMembers] = useState([])
      const [query, setQuery] = useState([])
      const [memSort, setMemSort] = useState(['name.lastname', 'name.firstname'])
      const [open, setOpen] = React.useState(false);
      
      //Get list of members
      useEffect(() => {
        setOpen(true)
        async function getMembers(){
          const members = await API.graphql({ query: queries.listMembers });
          console.log(members); 
          setMembers(members.data.listMembers.items)
          let sortedMembers = sortBy(members.data.listMembers.items, memSort);
          setFilteredMembers(sortedMembers)
          setOpen(false)
        }
        getMembers()
        
      }, []);
      
      
      //Filter member list by search query
      useEffect(() => {
        if(query.length > 1){
          var filteredmem = filter(members, mem=>{
            let name = `${mem.name.firstname} ${mem.name.middlename} ${mem.name.lastname}`
               if(includes(name.toLowerCase(), query.toLowerCase())) return true
               if(has(mem, 'email') && includes(mem.email.toLowerCase(), query.toLowerCase())) return true
               return false
          })
          let sortedMembers = sortBy(filteredmem, memSort);
          setFilteredMembers(sortedMembers);
        } else {
          let sortedMembers = sortBy(members, memSort);
          setFilteredMembers(sortedMembers)
        }
      }, [query, memSort]);
      
      return (
        <div className="page">
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <Chip raised component={RouterLink} color="primary" to="/new/member" className="action" label="New Kin" style={{cursor:'pointer'}} icon={<Add/>} />
        
        <ButtonGroup color="primary" aria-label="outlined primary button group" style={{backgroundColor:'lightgrey', borderRadius:12, marginLeft:10}}>
          
          <Button style={{ fontSize:11, padding:4}} onClick={()=>setMemSort(['name.lastname', 'name.firstname'])} variant={memSort.length > 1? 'contained':'outlined'}>Lastname</Button>
          <Button style={{ fontSize:11, padding:4}} onClick={()=>setMemSort(['birthdate'])} variant={memSort.length ===1? 'contained':'outlined'}>Birthdate</Button>
        </ButtonGroup>
       
        <div className={classes.searchWrapper}>
        <InputBase
          className={classes.input}
          placeholder="Search Kin"
          value={query}
          onChange={(e)=>setQuery(e.target.value)}
          inputProps={{ 'aria-label': 'search kin' }}
        />
        <IconButton type="submit" color="primary" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </div>
        </div>
        
        <div id="kin_list" style={{marginTop:20}}>
          {filteredMembers.map((member, index) => <MemberCard member={member} key={member.id} />)}
          </div>
          <Backdrop className={classes.backdrop} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
      </div>
      );
      
}