import {has} from 'lodash'

export const buildFullName = (member) =>{
    var prefix, firstname, middlename, lastname, suffix;
    if(has(member.name, "prefix")) prefix = <span className="member-name-prefix">{member.name.prefix}</span>;
    if(has(member.name, "firstname")) firstname = <span className="member-name-firstname">{member.name.firstname}</span>;
    if(has(member.name, "middlename")) middlename = <span className="member-name-middlename">{member.name.middlename}</span>;
    if(has(member.name, "lastname")) lastname = <span className="member-name-lastname">{member.name.lastname}</span>;
    
    if(has(member.name, "suffix")) suffix = <span className="member-name-suffix">{member.name.suffix}</span>;
    
    return <div style={{display:"inline-block"}} className="member-name">{prefix} {firstname} {middlename} {lastname} {suffix}</div>;
}

export const buildShortName = (member) => {
    var firstname, lastname;
    if(has(member.name, "firstname")) firstname = <span className="member-name-firstname">{member.name.firstname}</span>;
    if(has(member.name, "lastname")) lastname = <span className="member-name-lastname">{member.name.lastname}</span>;
    
    return <div style={{display:"inline-block"}} className="member-name">{firstname} {lastname}</div>;
}