import React from 'react';
import {Link as RouterLink} from 'react-router-dom'
import {Chip} from '@material-ui/core'
import {ChevronLeft, Edit, Share} from '@material-ui/icons'
import InviteDialog from './InviteDialog'
import '../Navigation.css';


function PersonSubNav(props){
    return <div className="subnav">
    
        <Chip raised component={RouterLink} color="primary" to="/" className="action" label="Back" icon={<ChevronLeft/>} />
        {props.member.id && <InviteDialog member={props.member} />}
        {props.member.id && <Chip color="secondary" component={RouterLink} to={"/edit/member/"+props.member.id} raised className="action" label="Edit" icon={<Edit />} />}
      </div>
    
}

export default PersonSubNav;