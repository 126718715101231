export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      name {
        prefix
        suffix
        firstname
        middlename
        lastname
      }
      profileKey
      phone
      email
      sex
      address
      abstract
      description
      birthdate
      media {
        items {
          id
          fileType
          assetKey
          createdAt
          updatedAt
        }
        nextToken
      }
      families {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          type
          member2 {
              id
              name {
                prefix
                suffix
                firstname
                middlename
                lastname
              }
              profileKey
              phone
              email
              sex
              abstract
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;