/*
  Entrypoint to the application
  
  This is where we will display application wide information.  
  i.e. Application header, app navigation and anything else that will be displayed on all pages of the app.
  
  The router controls the pages that are displayed relative to the url path
  
  This is where the authentication controller will be inserted
*/

import React from 'react';

import Navigation from './Components/Navigation'
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange  } from '@aws-amplify/ui-components';
import { SnackbarProvider } from 'notistack';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from './Utils/ScrollToTop'
import Routes from './Routes';
import './App.css';


function App() {
  const [authState, setAuthState] = React.useState();
 const [title, setTitle] = React.useState("KinInfo")
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);
  return authState === AuthState.SignedIn && user ? (
      <SnackbarProvider maxSnack={3} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }}>
        <div className="App">
      
        <Router>
          <ScrollToTop>
        <Navigation />
          <div style={{marginTop:80}}>
            <Routes />
          </div>
          </ScrollToTop>
        </Router>
      </div>
    </SnackbarProvider>
    ) : (
      <AmplifyAuthenticator
      usernameAlias="email"
      >
      
        
      <AmplifySignIn
        headerText="Welcome to KinInfo."
        headerSubtitle="Please Sign In."
        hideSignUp
        slot="sign-in"
        usernameAlias="email"
        formFields={[
          {
            label: "Your Email",
            key: "username",
            type: "email",
            placeholder: "Enter your email",
            required: true,
          },
          {
            type: "password",
            label: "Your password*",
            placeholder: "Enter your password",
            required: true,
          }
        ]} 
      ></AmplifySignIn>
      </AmplifyAuthenticator>
  );
}

export default App;

/*
<AmplifySignUp
        headerText="Welcome to KinInfo."
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            { type: "email" },
            { type: "phone_number" },
            { type: "password" }
          ]}
        />
        */