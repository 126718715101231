import React from 'react';
import {
    Button, 
    TextField, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem
    
} from '@material-ui/core';
import AssocAutoComplete from './AssocAutoComplete'
import {createAssociation} from '../../graphql/mutations';
import { API, graphqlOperation, Storage, Auth  } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';


export default function AssociationDialog({member, onAdd}) {
  const [open, setOpen] = React.useState(false);
  const [assocType, setAssocType] = React.useState("");
  const [assocMember, setAssocMember] = React.useState(null);
const { enqueueSnackbar } = useSnackbar();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveAndClose = async() => {
      //Create Association
      try {
    var assocObj = {
        id: uuidv4(),
    	type: assocType,
    	memberAssociationsId: member.id,
    	associationMember1Id: member.id,
    	associationMember2Id: assocMember.id,
    }
      
    await API.graphql(graphqlOperation(createAssociation, {input: assocObj}))
    enqueueSnackbar(`Association ADDED!`, { variant: 'success' });
    setOpen(false);
    
    onAdd()
      } catch(e){
        console.log(e.message)
        enqueueSnackbar(`Association could not be added!`, { variant: 'error' });
      }
  };
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{marginBottom:10}}>
        Add New Association
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">New Association</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new association to {member.name.firstname}:<br/>
            Search for the family member by name.<br/>
            Designate the type of assiociation.
          </DialogContentText>
          <AssocAutoComplete onValueChange={(m)=>setAssocMember(m)} />
          <br/>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="assoc-type">Association Type</InputLabel>
            <Select
              labelId="assoc-type-label"
              id="assoc-type-select"
              value={assocType}
              onChange={(e)=>setAssocType(e.target.value)}
              label="Age"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="FATHER">Father</MenuItem>
              <MenuItem value="MOTHER">Mother</MenuItem>
              <MenuItem value="STEPFATHER">Step Father</MenuItem>
              <MenuItem value="STEPMOTHER">Step Mother</MenuItem>
              <MenuItem value="SIBLING">Sibling</MenuItem>
              <MenuItem value="SPOUSE">Spouse</MenuItem>
              <MenuItem value="EXSPOUSE">Ex-Spouse</MenuItem>
              <MenuItem value="CHILD">Child</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveAndClose} color="primary">
            Create Association
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
