/* 
    Help File
*/

import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {Container, Paper, Chip} from '@material-ui/core'
import {ChevronLeft} from '@material-ui/icons'

function Help (props) {
  return <Container  className="page">
      <Chip style={{marginBottom:20, cursor:'pointer'}} raised component={Link} color="primary" to={"/"} className="action" label="Back" icon={<ChevronLeft/>} />
      <Container component={Paper} raised style={{marginBottom:20, minheight:200, padding:20}}>
<p class="H1" id='TOP'> Using the kin info website. <b>WORK IN PROGRESS</b></p>
<p class="description">Trying to keep up to date with a enlarging family
  is an arduous task for any single person and is usually out of date.&nbsp;
  This globally accessible web site will allow family members to update
  their own information and make it immediately available to the rest of
  the family.&nbsp; Furthermore it will carry more information regarding
  the individuals and their relationship to the family. </p>
<ul id="help">
  <li><a href="#secure">Login and Security</a></li>
  <li><a href="#home">The Home Index</a></li>
  <li><a href="#member">The Member View</a></li>
  <li><a href="#edit">The Member Editor</a></li>
  <li><a href="#bkgnd">Background Story</a></li>
</ul>
</Container> 
<Container id="secure" component={Paper} raised style={{marginBottom:20, minheight:200, padding:20}}>
<p class="h1">Login and security.&nbsp;<a href="#TOP">&nbsp;to Top</a></p>
<p class="description">KinInfo.net is a secure site. Information traveling
  between the site and your local browser is encrypted.&nbsp; The site is
  hosted by Amazon and owned by John Domogalla. It is basically a list of
  family members. Many members have the ability to log in and view the site
  as well as invite others. They are called users.&nbsp; Not all members are
  users.&nbsp; Some are dead, some are children. </p>
<p class="description">I wanted a distributed management system so that I
  don't need to be involved in expanding the user base.&nbsp; Existing
  users can create a new member and may invite that member to be a user.&nbsp;
  The existing user has the responsibility to evaluate a newly invited
  user's ability to behave responsibly and respectfully when invited to
  join.&nbsp; While logged on any user will be able to edit any member
  page. This allows users to edit children's pages and histories of
  deceased member's pages.</p>
<p class="description">The site operates on mobile devices as well as
  desktops. It uses a browser like Chrome, Firefox or Safari.&nbsp; There
  is a login prompt that expects the user identification to be the users
  email address combined with a password known only to the user.&nbsp; Once
  logged in from a particular browser on a particular device that browser
  will remember the authorization, bypass the login process and go
  directly to the main page. The Chrome browser on an Android phone can
  make a widget on the phone so with just one touch you can open the site
  to get quick contact information. To set that up expand the Chrome setting
  menu and select the "Add to home screen" after logging in.</p>
<p class="description">The site uses Multi-Factor-Authentication (MFA)
  same as banks and other secure sites. The one time only process starts
  with email receipt of an invitation from an existing user. It will show
  up addressed like ...</p>
  <Paper raised style={{padding:20, paddingTop:5, paddingBottom:5}}>
    <p> <strong>From:</strong>  invite@kininfo.net<br/>
      <strong>Subject:</strong> A family member invited you to contribute to KinInfo.net</p>
      <p>Go to KinInfo.net&nbsp; You may sign in as <strong>YOUR_email_address@someservice</strong> using this temporary password
      <strong> [########]</strong> (8char long) </p>
  </Paper>
<p class="description">The process is easier with a real keyboard.&nbsp;
  On the first page you put in your email and the password sent in the
  invitation. This causes the site to send a random code to the email you
  used and the page changes awaiting you to input the code and a 
  password that you made up and <strong>remembered to save.</strong>&nbsp; When that is done the site takes
  you to the login page where you log in as normal using your email
  address and your personal password.&nbsp; If you forget your password
  you will need to go through the MFA process again.</p>
<p class="description"> The invite will expire in 30 days.</p>
</Container> 
<Container id="home" component={Paper} raised style={{marginBottom:20,minheight:200, padding:20}}>
<p id="home" class="h1">The Home Index is the main entry page.&nbsp;<a href="#TOP">&nbsp;to Top</a></p>
<p class="description"></p>
<p class="description">The home page is an index of all of the family
  members electronic contact information.&nbsp;Clicking on an individual's listing will take you
  to that member's page. There is a search bar at
  the top since this list will inevitably get quite long as time goes
  on.&nbsp; Any sequence of characters typed in, will filter the list by
  finding, without case sensitivity, the names in the list containing
  those characters.</p>
<p class="description"> At the top left is a blue button ( + New Kin )
  .&nbsp; Pressing that will open a template member page to describe some
  NEW&nbsp; individual you wish to add to the family.&nbsp; <b>You should
    fill in at least </b>first and last name, as well as choose the
  sex.&nbsp; An email address must be added if you intend to invite the member as a
  user. When your satisfied with the page for the new member be sure to
  click on the "CREATE NEW MEMBER" button at the bottom right. </p>
<p class="description">For now, don't go on to add associations or upload
  other content.&nbsp; Seems to be bugs if to much is added when
  initializing the new member page. Instead return to the index. Then
  re-enter the just created member, then Edit the member.</p>
</Container>
<Container id="member" component={Paper} raised style={{marginBottom:20,minheight:200, padding:20}}>
<p class="h1">The Member page&nbsp;<a href="#TOP">&nbsp;to Top</a></p>
<p class="description">Clicking on an individuals listing on the home or
  index page will bring up a new page unique to that individual.&nbsp; At
  the top of the page are three buttons ( &lt; Back )&nbsp; ( Invite
  )&nbsp; ( Edit )&nbsp;&nbsp;&nbsp;</p>
<p class="description"> ( &lt; Back ) takes you back to the index.&nbsp; (
  Invite )&nbsp; sends an email invitation to the member to become a user
  of&nbsp; KinInfo.net. &nbsp; The email invitation will be sent to the
  email address displayed on the member page.&nbsp; Make sure it is
  correct. Go to the Login and Security section of this document for more
  information.&nbsp; ( Edit ) Allows you to change and add information to
  the members page. See the next section below.</p>
<p class="description">&nbsp;&nbsp; At the top of the display is basic
  contact information, a picture, and a brief abstract regarding the
  individual such as daily routine, notes regarding when to contact the
  member in any intrusive way, subjects to be avoided :) etc.&nbsp;&nbsp;
  On a deceased persons page define where they are buried with as much
  location detail as known, Or who has the ashes, or where is the mausoleum. &nbsp; </p>
<p class="description">The section below labeled Description is a text
  area for longer expositions. Generally stuff like grew up in someplace,
  got a degree, served in the military during...&nbsp; Married and
  Divorced some person not a member.&nbsp;&nbsp; If there's any genetics
  important to siblings. Or you can discuss what your interests are,
  what you've been doing, buying or planning currently.&nbsp;&nbsp; Why
  did you move too.....</p>
<p class="description">Midway down the member page is a section with three
  columns. Immediate family relationships are defined and clicking on the
  associations will take you directly to the member pages.&nbsp; By
  choosing “Parents” the user will be jumpin “up” the family tree. By
  choosing “Siblings &amp; Spouses” they will move horizontally on a
  family’s branch. By choosing “Children” they’ll be exploring down.&nbsp;
  At the moment there is no family tree graphic map.</p>
<p class="description">At the bottom of the page is area to post
  multimedia such as a <b>picture </b>of the member executing their
  favorite sport. ( <b>JPG </b>)&nbsp; By talking into a digital
  recorder or a similar app on a cell phone and creating a mp3 file, then
  uploading it to this section family members will have the sound of the
  members voice in perpetuity by simply clicking on the player.</p>
</Container> <Container id="edit" component={Paper} raised style={{marginBottom:20,minheight:200, padding:20}}>
<p class="h1">The Member Edit Page&nbsp;<a href="#TOP">&nbsp;to Top</a></p>
<p class="description">This mode is entered by pressing the&nbsp; (Edit )
  button on the member's display page. After making changes you will click
  the UPDATE member button before clicking on the ( &lt; Back ) button to
  exit the edit mode.</p>
<p class="description">ANYONE logged in can edit any members page. This
  allows users with information and time; to contribute to those who are
  not users or are busy.&nbsp; I don't want to write a huge policy document on how to
  be civil in a public arena.&nbsp; If you wish to trash a living member
  for personal affronts, please consider the rest of the family's need to know. I
  suggest using private email.&nbsp; If you believe your anonymous and
  destructive; your not anonymous.</p>
<p class="description">Much of the edit page looks like a form fill-out.
  Just click on the cells of the form and enter what you wish.&nbsp; There
  are embedded instructions on the edit page.&nbsp; After you drag a
  picture or audio file into the media section you must click the "upload
  media" button&nbsp; and wait a bit before clicking the update member
  button. </p>
<p class="description">An association is a link between two members. You
  will have to add the members to the data base first before using ADD NEW
  ASSOCIATION .&nbsp;&nbsp; This is done on the home-index page by clicking the
  ( + New Kin ) button at the top left.&nbsp;
  With both members in the database you can go to the edit mode on both
  members and select ADD NEW ASSOCIATION.&nbsp; You will then select the
  related member by selecting the member and association type from drop
  down menu’s and filter function. </p>
<p class="description">Nothing is automatically saved.&nbsp; After you've
  modified Abstract or Description or Media, click update member before
  leaving the edit mode.</p>
</Container> <Container id="bkgnd" component={Paper} raised style={{marginBottom:20,minheight:200, padding:20}}>
<p class="h1">Why KinInfo.net?&nbsp;<a href="#TOP">&nbsp;to Top</a></p>
<p class="description">Until about 2015 Dot(Dorthy) Dodds would put out a 
  contact list called "Green Family" annually.  But she, getting older, stopped updating the document.
  I had been converting ancestry information that my wife, Jeannette,
  had collected, into a system of linked web pages trying to avoid the proprietary tools Jeannette used. 
  I thought a replacement for Dot's document would be a interactive website
  which would provide a single location to record individual's contact 
  information and relationships, but also add a storyline to their lives 
  beyond the fact that they had a name, lived and died.
</p>
<p class="description">Instead of having many copies of out-of-date
  information copied to computers and paper all over the country I wanted a central
  location where the information could be uniquely stored while being
  widely distributed. I did not want to act as the central administrator taking
  updates from many people and loading them on to the database, which is
  the equivalent of Dot's hard work. Her document was also trying to demonstrate
  ancestry by using indented formatting. The letter was
  becoming very complex and confusing as the family extended. kininfo is intended as a replacement.</p>
<p class="description">At first I created a very simple structure of linked HTML
  pages but I had trouble solving the multi-user editing of HTML pages
  and became frustrated trying to "publish" the website using google's
  free services. So I called my son Dero who is an expert on such things.
  Dero grabbed the concept and took off with it. We owe him significantly and
  the accolades could go on for quite awhile.</p>
<p class="description">The site has pages that can carry much
  more information than just contact and relationship information for an
  individual . An individual's page contains hyperlinks to family members.
  By clicking on the links you can "jump" to Father, Mother, Sibling's,
  Spouses and so on. People are more than just an email address. They have
  a storyline and a face and maybe some pictures demonstrating their
  favorite sport. They might be able to advise or help if you only knew
  what they knew and how to contact them. I did not intend this site to
  replicate Facebook. I did intend it to be like an ancestry site but one
  that deals with the present and future as well as the past. In the
  future it is inevitable that we will all be in the past, and the records
  of us would be best constructed in the present by ourselves.</p>
<p class="description">As a user of kininfo when something changes you
  simply log on and change it. You don't need to call me or anyone else.
  Neither Dot or Donna or Dero or anyone need be the "manager". As soon as
  you change it everyone in the greater family can instantly see the
  updated information. The information can be viewed from any internet
  capable device that has a standard browser. ( cell phone, pad, desktop,
  etc... )</p>
<p class="description">The site will expand automatically as the family
  members expand it. We will not run out of space.</p>
</Container>

</Container>;
        
}

export default withRouter(Help)