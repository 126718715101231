/* This will be a dynamic person viewer. 
    The person displayed is based on the id indicated in the url path
    The person data is passed on to the children compoents (headerblock, description, etc)
        this allows all information about each person to be dynamically displayed.
*/
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import {Link as RouterLink} from "react-router-dom";
import {TextField, Grid, Paper, Card, Select, FormControl, InputLabel, MenuItem, Button, Typography, Chip, Backdrop, CircularProgress } from '@material-ui/core'
import MomentUtils from '@date-io/moment';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import {find} from 'lodash';
import IS from '../Components/ImageService';
import {updateMember, createMedia} from '../graphql/mutations';
import * as queries from '../graphql/savedQueries';
import { API, graphqlOperation, Storage, Auth  } from 'aws-amplify';
import { ChevronLeft, Publish } from '@material-ui/icons';
import AssociationCard from '../Components/Associations/AssociationCard'
import AssociationDialog from '../Components/Associations/AssociationDialog'
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


function UpdateMember (props) {
    const classes = useStyles();
    const [working, setWorking] = useState(false);
    const [id, setId] = useState("")
    const [associations, setAssociations] = useState([])
    const [sex, setSex] = useState("")
    const [address, setAddress] = useState("")
    const [prefix, setPrefix] = useState("")
    const [suffix, setSuffix] = useState("")
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [abstract, setAbstract] = useState("")
    const [description, setDescription] = useState("")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [profilePic, setProfilePic] = useState(null);
    const [picHasChanged, setPicHasChanged] = useState(false);
    const [member, setMember] = useState(null)
    const [mediaFiles, setMediaFiles] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    
    const handleChangeStatus = ({ meta, file }, status) => { 
        setProfilePic(file);
        setPicHasChanged(true)
        console.log(status, meta, file)
    }
    
    useEffect(() => {
        setWorking(true)
        getMember();
        
      }, [props.match.params.id]);
        
      const getMember = async ()=> {
            const memberdata = await API.graphql({ query: queries.getMember, variables: { id:  props.match.params.id} });
            const md = memberdata.data.getMember
            console.log("MEMBER DATA", md)
            setId(md.id)
            setSex(md.sex);
            setAddress(md.address);
            setFirstName(md.name.firstname);
            setMiddleName(md.name.middlename);
            setLastName(md.name.lastname);
            setPrefix(md.name.prefix);
            setSuffix(md.name.suffix);
            setEmail(md.email);
            setPhone(md.phone);
            setAbstract(md.abstract);
            setDescription(md.description);
            setSelectedDate(md.birthdate);
            setProfilePic(await IS(md.profileKey, md.sex))
            setAssociations(md.associations.items)
            setMember(md)
            setWorking(false)
        }
    
    //User has submitted the form
    //Upload state to dynamoDB
    const handleFormSubmit = async() =>{
       try {
           setWorking(true)
        const user = await Auth.currentAuthenticatedUser()
        console.log("USER", user)
        var memberObj = {
            id: id,
            
            name: {
                prefix: prefix,
                suffix:suffix,
              firstname: firstName,
              middlename: middleName,
              lastname: lastName
            },
            phone: phone,
            email: email,
            sex: sex,
            address: address,
            abstract: abstract,
            description: description,
            birthdate: selectedDate
        }
        
        if(picHasChanged){
            var fileext = (/[.]/.exec(profilePic.name)) ? /[^.]+$/.exec(profilePic.name) : undefined;
            console.log("FileExt", fileext)
            const pPic = await Storage.put(id+'/profile.'+fileext[0].toLowerCase(), profilePic).catch(err => console.log(err));
            console.log("PIC UPLOAD", pPic)
            memberObj.profileKey = pPic.key;
        }
        
        await API.graphql(graphqlOperation(updateMember, {input: memberObj}))
        console.log("MEMBER UPDATED");
        enqueueSnackbar(`${memberObj.name.firstname} ${memberObj.name.lastname} as been UPDATED!`, { variant: 'success' });
        setWorking(false)
       }
       catch(e){
           console.log("FAILED", e.message)
           enqueueSnackbar(`${memberObj.name.firstname} ${memberObj.name.lastname} update failed...`, { variant: 'error' });
           setWorking(false)
       }
    }
    
    const handleMediaChangeStatus = ({ meta, file }, status) => { 
        if(status === "done"){
            setMediaFiles([...mediaFiles, file])
           console.log(status, meta, file) 
        }
        
    }
    
    const uploadMediaFiles = async()=>{
        mediaFiles.reduce((p, file) => {
             return p.then(() => uploadMedia(file));
          }, Promise.resolve()); // initial
        
    }
    
    const uploadMedia = async(file)=>{
        //Upload to S3
        var fileext = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : undefined;
        console.log("FileExt", fileext)
        const mUp = await Storage.put(id+'/media/'+file.name.toLowerCase(), file).catch(err => console.log(err));
        console.log("Media UPLOAD", mUp)
        
        //Create Reference in Database
        var mediaId = uuidv4()
        var mediaObj = {
            id:mediaId,
            assetKey:mUp.key,
            fileType: file.type,
            memberMediaId:id,
            mediaMemberId:id
        }
        await API.graphql(graphqlOperation(createMedia, {input: mediaObj}))
    }
    
    const getProfilePic=()=>{
        if(profilePic){
            return "https://futurefamilymedia205301-dev.s3.us-east-2.amazonaws.com/public/"+id+"/"+profilePic
        }
        return "https://futurefamilymedia205301-dev.s3.us-east-2.amazonaws.com/siteassets/generic-profile-icon-8.jpg"
    }
  
  
        return <div  className="page">
            <Chip style={{marginBottom:20, cursor:'pointer'}} raised component={RouterLink} color="primary" to={"/member/"+id} className="action" label="Back" icon={<ChevronLeft/>} />
            <form  noValidate autoComplete="off" style={{backgroundColor:'white', borderRadius:8}}>
                <Grid container className="abstract">
                    <Grid item xs={12} md={3} className="profilepic" style={{position:'relative'}}>
                        <div className="upload_outline" ><img src={profilePic} /></div>
                        <Dropzone
                            autoUpload={false}
                            maxFiles={1}
                            multiple={false}
                            onChangeStatus={handleChangeStatus}
                            submitButtonComponent={null}
                            inputContent={(files, extra) => (extra.reject ? 'Image, audio and video files only' : 'Tap here or drag file here to upload profile picture')}
                            accept="image/*"
                        />
                    </Grid>
                    <Grid item xs={12} md={9} spacing={3}>
                        <Grid container spacing={3} style={{padding:20}}>
                            <Grid item xs={4} md={1}>
                                <TextField label="Prefix" value={prefix} fullWidth onChange={(e)=>setPrefix(e.target.value)}/>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <TextField label="First Name" value={firstName} fullWidth onChange={(e)=>setFirstName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <TextField label="Middle Name" value={middleName} fullWidth onChange={(e)=>setMiddleName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField label="Last Name" value={lastName} fullWidth onChange={(e)=>setLastName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={6} md={1}>
                                <TextField label="Suffix" value={suffix} fullWidth onChange={(e)=>setSuffix(e.target.value)}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Email" type="email" value={email} fullWidth onChange={(e)=>setEmail(e.target.value)}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Phone" value={phone} fullWidth onChange={(e)=>setPhone(e.target.value)}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Sex</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sex}
                                        onChange={(e)=>setSex(e.target.value)}
                                    >
                                        <MenuItem value="MALE">Male</MenuItem>
                                        <MenuItem value="FEMALE">Female</MenuItem>
                                        <MenuItem value="OTHER">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={9}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        disableFuture
                                        openTo="year"
                                        format="dddd MMM Do, yyyy"
                                        label="Date of birth"
                                        views={["year", "month", "date"]}
                                        value={selectedDate}
                                        onChange={(date)=>setSelectedDate(date)}
                                        fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <TextField label="Address"  value={address} fullWidth variant="outlined" onChange={(e)=>setAddress(e.target.value)}/>
                            </Grid>
                          
                            <Grid item xs={12}>
                                <TextField label="Abstract" value={abstract} fullWidth variant="outlined" multiline  onChange={(e)=>setAbstract(e.target.value)}/>
                            </Grid>
                            
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="description" style={{padding:20}}>
                    <Grid item xs={12} >
                        <TextField label="Description" value={description} fullWidth multiline variant="outlined"  onChange={(e)=>setDescription(e.target.value)}/>
                    </Grid>
                </Grid>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', marginTop:0, padding:20}}>
                
                <Button variant="contained" color="primary"  disabled={working} onClick={()=>handleFormSubmit()}>
                    <Publish /> Update {firstName} {lastName}
                </Button>
                </div>
            </form>
            
            
            <Paper raised  style={{marginTop:20, padding:20}}>
            {member && <AssociationDialog member={member} onAdd={getMember} />}
                <Typography style={{display:'block'}}>
                    Associations
                </Typography>
                {associations.map(assoc=>
                <AssociationCard association={assoc} editMode onRemove={getMember}/>
                )}
            </Paper>
            
            <Paper raised  style={{marginTop:20, padding:10}} className="media-upload">
            <Typography style={{display:'block'}}>
                    Media
                </Typography>
            <Dropzone
                autoUpload={false}
                onChangeStatus={handleMediaChangeStatus}
                inputContent={(files, extra) => (extra.reject ? 'Image, audio and video files only' : 'Tap here or drag files here to add media')}
                accept="image/*,video/*,audio/*"
            />
            {mediaFiles.length > 0 && 
            <Button variant="contained" color="success" disabled={working} onClick={()=>uploadMediaFiles()}>
                    <Publish /> Upload Files
                </Button>
            }
            </Paper>
            <Backdrop className={classes.backdrop} open={working}>
                <CircularProgress color="inherit" />
              </Backdrop>
        </div>;
        
  
}



export default withRouter(UpdateMember)