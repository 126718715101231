import React from "react";
import { Route, withRouter } from "react-router-dom";
import { AnimatedSwitch } from "./Utils/AnimatedSwitch";
import Home from './Pages/Home'
import Member from './Pages/Member'
import NewMember from './Pages/NewMember'
import UpdateMember from './Pages/UpdateMember'
import Help from './Pages/Help'
/**
 * The ".page" class is key to animating a full page and not receive bumps while
 * animating pages in/out. It is position: fixed to allow the animation to play
 * without the DOM elements messing up.
 *
 * Try to remove .page to see the effect.
 */



const routes = [
  {
    component: Home,
    path: "/"
  },
  {
    component: Help,
    path: "/help"
  },
  {
    component: NewMember,
    path: "/new/member"
  }
  ,
  {
    component: UpdateMember,
    path: "/edit/member/:id"
  }
  ,
  {
    component: Member,
    path: "/member/:id"
  }
];


    
       

const Routes = withRouter(({ location }) => {
  return (
    <AnimatedSwitch location={location}>
      {routes.map(route => {
        return (
          <Route
            exact
            key={route.path}
            path={route.path}
            component={route.component}
          />
        );
      })}
    </AnimatedSwitch>
  );
});

export default Routes;