/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFamily = /* GraphQL */ `
  query GetFamily($id: ID!) {
    getFamily(id: $id) {
      id
      name
      members {
        items {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFamilys = /* GraphQL */ `
  query ListFamilys(
    $filter: ModelFamilyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFamilys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        members {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      name {
        prefix
        suffix
        firstname
        middlename
        lastname
      }
      profileKey
      phone
      email
      sex
      address
      abstract
      description
      birthdate
      media {
        items {
          id
          assetKey
          fileType
          createdAt
          updatedAt
        }
        nextToken
      }
      families {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      claimedMember {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      email
      phone
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        claimedMember {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        email
        phone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChange = /* GraphQL */ `
  query GetChange($id: ID!) {
    getChange(id: $id) {
      id
      user
      fields
      fromValues
      toValues
      createdAt
      updatedAt
    }
  }
`;
export const listChanges = /* GraphQL */ `
  query ListChanges(
    $filter: ModelChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        fields
        fromValues
        toValues
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      assetKey
      fileType
      member {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMedias = /* GraphQL */ `
  query ListMedias(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assetKey
        fileType
        member {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssociation = /* GraphQL */ `
  query GetAssociation($id: ID!) {
    getAssociation(id: $id) {
      id
      type
      member1 {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      member2 {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAssociations = /* GraphQL */ `
  query ListAssociations(
    $filter: ModelAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssociations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        member1 {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        member2 {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStory = /* GraphQL */ `
  query GetStory($id: ID!) {
    getStory(id: $id) {
      id
      storyMember {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        cognitoId
        claimedMember {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        email
        phone
        createdAt
        updatedAt
      }
      story
      createdAt
      updatedAt
    }
  }
`;
export const listStorys = /* GraphQL */ `
  query ListStorys(
    $filter: ModelStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storyMember {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        user {
          id
          cognitoId
          email
          phone
          createdAt
          updatedAt
        }
        story
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
