import React, {useEffect, useState}  from 'react'
import {has} from 'lodash'

import {Container, Grid, List, ListItem, ListItemText, ListItemIcon,ListSubheader , Typography, Paper} from '@material-ui/core'
import {PersonOutline, Phone, Email, Room, Cake} from '@material-ui/icons'
import {buildFullName} from '../../Utils/uihelpers'
import moment from 'moment'

import IS from '../ImageService'
/* The header block will contain an editable profile picture and bio information about the person */

function HeaderBlock(props){
    const [profilePic, setProfilePic] = useState(null)
    useEffect(() => {
        getProfilePic();
      }, [props.member]);
    
    const getProfilePic=async()=>{
        console.log(props.member.profileKey)
       setProfilePic(await IS(props.member.profileKey, props.member.sex))
    }
    
    return  <Container className="member-page" component={Paper} raised style={{marginBottom:20, minHeight:200, padding:20}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                            {profilePic && <img src={profilePic} name="nopic" className="profilePic" />}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <List aria-label="main information" style={{paddingTop:0}}>
                        <Typography variant="h5" component="h5">
                              Contact
                            </Typography>
                            <ListItem >
                                <ListItemIcon>
                                    <PersonOutline />
                                </ListItemIcon>
                                <ListItemText primary={buildFullName(props.member)} size="large" />
                            </ListItem>
                            <ListItem >
                                <ListItemIcon>
                                    <Phone />
                                </ListItemIcon>
                                {props.member.phone && <a  href={"tel:"+props.member.phone.replace(/\D/g, "")} style={{color:"#f50057", textDecoration: "none", fontSize:16 }}> {props.member.phone}</a>}
                            </ListItem>
                            <ListItem >
                                <ListItemIcon>
                                    <Email />
                                </ListItemIcon>
                                {props.member.email && <a href={"mailto:"+props.member.email}  style={{color:"#f50057", textDecoration: "none", fontSize:16 }}> {props.member.email} </a>}
                            </ListItem>
                            <ListItem >
                                <ListItemIcon>
                                    <Room />
                                </ListItemIcon>
                                <ListItemText primary={props.member.address} />
                            </ListItem>
                            <ListItem >
                                <ListItemIcon>
                                    <Cake />
                                </ListItemIcon>
                                <ListItemText primary={moment(props.member.birthdate).format("dddd MMM Do, yyyy")} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4}>
                            <Typography variant="h5" component="h5">
                             Abstract
                            </Typography>
                            <Typography style={{marginTop:10}}>
                                {props.member.abstract}
                            </Typography>
                      
                    </Grid>
                
                </Grid>
          </Container>
}

export default HeaderBlock;