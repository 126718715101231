import React, {useEffect, useState} from 'react';
import {Paper, Grid, Typography, Container} from '@material-ui/core'
import {has, filter, includes} from 'lodash';
import ReactAudioPlayer from 'react-audio-player';
import IS from '../ImageService'

export default function AudioCard(props){
    const [audio, setAudio]= useState(null);
    
    useEffect(() => {
        async function getAudio(){
            if(has(props.media, "assetKey"))setAudio(await IS(props.media.assetKey))
        }
        getAudio();
    }, [props.media]);
    

    if(!props.media) return null;
    return <ReactAudioPlayer
          src={audio}
          
          controls
        />
    
    
    
            
}