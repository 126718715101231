/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const inviteUser = /* GraphQL */ `
  mutation InviteUser(
    $id: String
    $email: String
    $phone: String
    $MFA: String
  ) {
    inviteUser(id: $id, email: $email, phone: $phone, MFA: $MFA)
  }
`;
export const createFamily = /* GraphQL */ `
  mutation CreateFamily(
    $input: CreateFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    createFamily(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFamily = /* GraphQL */ `
  mutation UpdateFamily(
    $input: UpdateFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    updateFamily(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFamily = /* GraphQL */ `
  mutation DeleteFamily(
    $input: DeleteFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    deleteFamily(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      name {
        prefix
        suffix
        firstname
        middlename
        lastname
      }
      profileKey
      phone
      email
      sex
      address
      abstract
      description
      birthdate
      media {
        items {
          id
          assetKey
          fileType
          createdAt
          updatedAt
        }
        nextToken
      }
      families {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      name {
        prefix
        suffix
        firstname
        middlename
        lastname
      }
      profileKey
      phone
      email
      sex
      address
      abstract
      description
      birthdate
      media {
        items {
          id
          assetKey
          fileType
          createdAt
          updatedAt
        }
        nextToken
      }
      families {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      name {
        prefix
        suffix
        firstname
        middlename
        lastname
      }
      profileKey
      phone
      email
      sex
      address
      abstract
      description
      birthdate
      media {
        items {
          id
          assetKey
          fileType
          createdAt
          updatedAt
        }
        nextToken
      }
      families {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      claimedMember {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      email
      phone
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      claimedMember {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      email
      phone
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoId
      claimedMember {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      email
      phone
      createdAt
      updatedAt
    }
  }
`;
export const createChange = /* GraphQL */ `
  mutation CreateChange(
    $input: CreateChangeInput!
    $condition: ModelChangeConditionInput
  ) {
    createChange(input: $input, condition: $condition) {
      id
      user
      fields
      fromValues
      toValues
      createdAt
      updatedAt
    }
  }
`;
export const updateChange = /* GraphQL */ `
  mutation UpdateChange(
    $input: UpdateChangeInput!
    $condition: ModelChangeConditionInput
  ) {
    updateChange(input: $input, condition: $condition) {
      id
      user
      fields
      fromValues
      toValues
      createdAt
      updatedAt
    }
  }
`;
export const deleteChange = /* GraphQL */ `
  mutation DeleteChange(
    $input: DeleteChangeInput!
    $condition: ModelChangeConditionInput
  ) {
    deleteChange(input: $input, condition: $condition) {
      id
      user
      fields
      fromValues
      toValues
      createdAt
      updatedAt
    }
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      id
      assetKey
      fileType
      member {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMedia = /* GraphQL */ `
  mutation UpdateMedia(
    $input: UpdateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    updateMedia(input: $input, condition: $condition) {
      id
      assetKey
      fileType
      member {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedia = /* GraphQL */ `
  mutation DeleteMedia(
    $input: DeleteMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    deleteMedia(input: $input, condition: $condition) {
      id
      assetKey
      fileType
      member {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAssociation = /* GraphQL */ `
  mutation CreateAssociation(
    $input: CreateAssociationInput!
    $condition: ModelAssociationConditionInput
  ) {
    createAssociation(input: $input, condition: $condition) {
      id
      type
      member1 {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      member2 {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAssociation = /* GraphQL */ `
  mutation UpdateAssociation(
    $input: UpdateAssociationInput!
    $condition: ModelAssociationConditionInput
  ) {
    updateAssociation(input: $input, condition: $condition) {
      id
      type
      member1 {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      member2 {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssociation = /* GraphQL */ `
  mutation DeleteAssociation(
    $input: DeleteAssociationInput!
    $condition: ModelAssociationConditionInput
  ) {
    deleteAssociation(input: $input, condition: $condition) {
      id
      type
      member1 {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      member2 {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStory = /* GraphQL */ `
  mutation CreateStory(
    $input: CreateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    createStory(input: $input, condition: $condition) {
      id
      storyMember {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        cognitoId
        claimedMember {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        email
        phone
        createdAt
        updatedAt
      }
      story
      createdAt
      updatedAt
    }
  }
`;
export const updateStory = /* GraphQL */ `
  mutation UpdateStory(
    $input: UpdateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    updateStory(input: $input, condition: $condition) {
      id
      storyMember {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        cognitoId
        claimedMember {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        email
        phone
        createdAt
        updatedAt
      }
      story
      createdAt
      updatedAt
    }
  }
`;
export const deleteStory = /* GraphQL */ `
  mutation DeleteStory(
    $input: DeleteStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    deleteStory(input: $input, condition: $condition) {
      id
      storyMember {
        id
        name {
          prefix
          suffix
          firstname
          middlename
          lastname
        }
        profileKey
        phone
        email
        sex
        address
        abstract
        description
        birthdate
        media {
          nextToken
        }
        families {
          nextToken
        }
        associations {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        cognitoId
        claimedMember {
          id
          profileKey
          phone
          email
          sex
          address
          abstract
          description
          birthdate
          createdAt
          updatedAt
        }
        email
        phone
        createdAt
        updatedAt
      }
      story
      createdAt
      updatedAt
    }
  }
`;
