import React, {useState, useEffect} from 'react';
import {
    Card, 
    CardHeader, 
    CardMedia, 
    CardContent, 
    CardActions, 
    CardActionArea,
    Collapse, 
    Avatar,
    IconButton,
    Typography,
    Select,
    MenuItem,
    Chip
} from '@material-ui/core';


import { makeStyles, useTheme  } from '@material-ui/core/styles';
import clsx from 'clsx';
import { red } from '@material-ui/core/colors';
import {
  ChevronRight,
  Email,
  PhoneAndroid
  } from '@material-ui/icons';
import IS from './ImageService'
import {buildShortName} from '../Utils/uihelpers' 
import {Link as RouterLink} from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {has} from 'lodash'

const useStyles = makeStyles((theme) => ({
 root: {
    display: 'flex',
    margin:5
  },
  actionarea:{
    display: 'flex',
    justifyContent:'flex-start'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flex:5
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width:140,
    minHeight:140,
    maxHeight:'100%',
    height:'100%',
    flex:1,
    objectFit:'cover',
    minWidth:100
  },
  chip: {
    margin:5
  },
  controls: {
    display: 'flex',
    flex:1,
    justifySelf:'flex-end',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function MemberCard({member}) {
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const matches = useMediaQuery('(min-width:600px)');
  useEffect(() => {
        async function getProfile(){
          setProfileImage(await IS(member.profileKey))
        }
        getProfile();
      }, []);
  
  
  
  return (
    <Card raised  className={classes.root} >
    <CardActionArea className={classes.actionarea} component={RouterLink} to={"/member/"+member.id}>
    <CardMedia
        className={classes.cover}
        image={profileImage}
        title={member.name.firstname + " " + member.name.lastname}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component={matches?"h5":"h6"} variant={matches?"h5":"h6"}>
            {buildShortName(member)}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {has(member, 'phone') && member.phone.length > 0 && member.phone !== "NA" && <Chip icon={<PhoneAndroid />} component="a" href={"tel:"+member.phone} label={member.phone} className={classes.chip} />}
            {has(member, 'email') && member.email.length > 0 && member.email !== "NA" && <Chip icon={<Email />} component="a" href={"mailto:"+member.email} label={member.email} className={classes.chip}  />}
          </Typography>
        </CardContent>
        
      </div>
      <CardActions>
        <IconButton aria-label={"View"+member.name.firstname}>
          <ChevronRight />
        </IconButton>
      </CardActions>
      </CardActionArea>
    </Card>
  );
}
