/* This will be a dynamic person viewer. 
    The person displayed is based on the id indicated in the url path
    The person data is passed on to the children compoents (headerblock, description, etc)
        this allows all information about each person to be dynamically displayed.
*/
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import {Link as RouterLink, useHistory} from "react-router-dom";
import {TextField, Grid, Paper, Card, Select, FormControl, InputLabel, MenuItem, Button, Chip } from '@material-ui/core'
import MomentUtils from '@date-io/moment';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import {find} from 'lodash';
import {createMember} from '../graphql/mutations';
import { ChevronLeft, Publish } from '@material-ui/icons';
import { API, graphqlOperation, Storage, Auth  } from 'aws-amplify';
import { useSnackbar } from 'notistack';

function NewMember (props) {
    const [associations, setAssociations] = useState([])
    const [sex, setSex] = useState("MALE")
    const [address, setAddress] = useState("")
    const [prefix, setPrefix] = useState("")
    const [suffix, setSuffix] = useState("")
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [abstract, setAbstract] = useState("")
    const [description, setDescription] = useState("")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [profilePic, setProfilePic] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [working, setWorking] = useState(false)
    const history = useHistory();
    
    const handleChangeStatus = ({ meta, file }, status) => { 
        setProfilePic(file);
        console.log(status, meta, file)
    }
    
    //User has submitted the form
    //Upload state to dynamoDB
    const handleFormSubmit = async() =>{
        setWorking(true)
       try {
           const user = await Auth.currentAuthenticatedUser()
        console.log("USER", user)
        var memberObj = {
            id: uuidv4(),
            
            name: {
                prefix: prefix,
                suffix:suffix,
              firstname: firstName,
              middlename: middleName,
              lastname: lastName
            },
            phone: phone,
            email: email,
            sex: sex,
            address: address,
            abstract: abstract,
            description: description,
            birthdate: selectedDate
        }
        
        if(profilePic){
            var fileext = (/[.]/.exec(profilePic.name)) ? /[^.]+$/.exec(profilePic.name) : undefined;
            const pPic = await Storage.put(memberObj.id+'/profile.'+fileext[0].toLowerCase(), profilePic).catch(err => console.log(err));
            console.log("PIC UPLOAD", pPic)
            
            memberObj.profileKey = pPic.key;
        }
        
        await API.graphql(graphqlOperation(createMember, {input: memberObj}))
        console.log("MEMBER CREATED");
        //Display feedback
        enqueueSnackbar(`${memberObj.name.firstname} ${memberObj.name.lastname} as been CREATED!`, { variant: 'success' });
        setWorking(false)
        //Move user to editable interface with more options
        history.push("/edit/member/"+ memberObj.id);
        
       }
       catch(e){
           console.log("FAILED", e)
           enqueueSnackbar(`${memberObj.name.firstname} ${memberObj.name.lastname} creation failed...`, { variant: 'error' });
           setWorking(false)
       }
    }
  
  
        return <div  className="page">
         <Chip style={{cursor:'pointer'}} raised component={RouterLink} color="primary" to={"/"} className="action" label="Back" icon={<ChevronLeft/>} />
            <form  noValidate autoComplete="off"  style={{backgroundColor:'white', borderRadius:8, padding:20, marginTop:20}}>
                <Grid container className="abstract">
                    <Grid item xs={12} md={3} className="profilepic" style={{position:'relative'}}>
                        <div className="upload_outline" ><img src="https://futurefamilymedia205301-dev.s3.us-east-2.amazonaws.com/siteassets/generic-profile-icon-8.jpg" /></div>
                        <Dropzone
                        autoUpload={false}
                        maxFiles={1}
                        multiple={false}
                          onChangeStatus={handleChangeStatus}
                          submitButtonComponent={null}
                          inputContent={(files, extra) => (extra.reject ? 'Image, audio and video files only' : 'Tap here or drag files here to upload profile picture')}
                          accept="image/*"
                        />
                    </Grid>
                    <Grid item xs={12} md={9} spacing={3}>
                        <Grid container spacing={3} >
                            <Grid item xs={1} md={1}>
                                <TextField label="Prefix" value={prefix} fullWidth onChange={(e)=>setPrefix(e.target.value)}/>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <TextField label="First Name" value={firstName} fullWidth onChange={(e)=>setFirstName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <TextField label="Middle Name" value={middleName} fullWidth onChange={(e)=>setMiddleName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <TextField label="Last Name" value={lastName} fullWidth onChange={(e)=>setLastName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={1} md={1}>
                                <TextField label="Suffix" value={suffix} fullWidth onChange={(e)=>setSuffix(e.target.value)}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Email" type="email" value={email} fullWidth onChange={(e)=>setEmail(e.target.value)}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Phone" value={phone} fullWidth onChange={(e)=>setPhone(e.target.value)}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Sex</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sex}
                                        onChange={(e)=>setSex(e.target.value)}
                                    >
                                        <MenuItem value="MALE">Male</MenuItem>
                                        <MenuItem value="FEMALE">Female</MenuItem>
                                        <MenuItem value="OTHER">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={9}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        disableFuture
                                        openTo="year"
                                        format="dddd MMM Do, yyyy"
                                        label="Date of birth"
                                        views={["year", "month", "date"]}
                                        value={selectedDate}
                                        onChange={(date)=>setSelectedDate(date)}
                                        fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <TextField label="Address" placeholder="Address, City, State, Zip"  value={address} fullWidth onChange={(e)=>setAddress(e.target.value)}/>
                            </Grid>
                          
                            <Grid item xs={12}>
                                <TextField label="Abstract" value={abstract} fullWidth variant="outlined" multiline  onChange={(e)=>setAbstract(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="description" style={{padding:20}}>
                    <Grid item xs={12} >
                    <TextField label="Description" fullWidth multiline variant="outlined"  onChange={(e)=>setDescription(e.target.value)}/>
                    </Grid>
                </Grid>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', marginBottom:20, paddingRight:20}}>
                <Button variant="contained" color="primary" disabled={working} onClick={()=>handleFormSubmit()}>
                  Create New Member
                </Button>
                </div>
            </form>
        </div>;
        
  
}



export default withRouter(NewMember)