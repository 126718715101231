import React from 'react';
import {
    Button, 
    TextField, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip
    
} from '@material-ui/core';
import AssocAutoComplete from './AssocAutoComplete'
import {deleteAssociation} from '../../graphql/mutations';
import { API, graphqlOperation, Storage, Auth  } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';


export default function AssociationDialog({association, onRemove}) {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleRemove = async() => {
      //Create Association
      try {
      
    await API.graphql(graphqlOperation(deleteAssociation, {input: {id:association.id}}))
    enqueueSnackbar(`Association REMOVED`, { variant: 'success' });
    setOpen(false);
    
    onRemove()
      } catch(e){
        console.log(e)
        enqueueSnackbar(`Association could not be removed!`, { variant: 'error' });
      }
  };
  return (
    <div>
      <Chip color="primary" className="trashicon" style={{position:'absolute', top:0, right:0, width:20, justifyContent:'center'}} label={null} size="small" onDelete={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Remove Association</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove {association.member2.name.firstname}?
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outline">
            Cancel
          </Button>
          <Button onClick={handleRemove} style={{backgroundColor:'red', color:'white'}}>
            Remove Association
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
