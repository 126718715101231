import React, {useEffect, useState} from 'react';
import {Paper, Grid, Typography, Container, GridList, GridListTile, Dialog} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import {has, filter, includes} from 'lodash';
import ImageCard from '../Media/ImageCard'
import AudioCard from '../Media/AudioCard'
import IS from '../ImageService'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 450,
  },
}));
export default function Media(props){
    const [mediaFiles, setMediaFiles]= useState([]);
    const [viewerOpen, setViewerOpen]= useState(false);
    const [fileToView, setFileToView] = useState(null)
    const classes = useStyles();
    useEffect(() => {
        if(has(props.member, "media")){
            setMediaFiles(props.member.media.items)
        }
    }, [props.member.media]);
    
    const displayFile = (media)=>{
        console.log(media)
        if(includes(media.fileType, "image")) {
            
            return <ImageCard media={media} />
        }
        if(includes(media.fileType, "audio")) {
            
            return <AudioCard media={media} />
        }
        return null
    }
    const handleClose = () => {
    setViewerOpen(false)
  };
    
    return <Container component={Paper} raised style={{marginBottom:20, minHeight:200, padding:20}}>
    <Typography variant="h5" component="h5">
                             Media
                            </Typography>
       
            <GridList cellHeight={160} className={classes.gridList} cols={3}>
  {mediaFiles.map(m=>
    <GridListTile key={m.id} cols={1} onClick={()=>{setFileToView(m); setViewerOpen(true);}}>
      {displayFile(m)}
    </GridListTile>
  )}
</GridList>

    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={viewerOpen}>
      {fileToView && displayFile(fileToView)}
    </Dialog>
    </Container>
    
    
    
            
}