import React, {useEffect, useState} from 'react';
import {Paper, Grid, Typography} from '@material-ui/core'
import AssociationCard from '../Associations/AssociationCard'
import {has, filter, includes} from 'lodash';


export default function MemberAssociations(props){
    const [associations, setAssociations]= useState(null);
    
    useEffect(() => {
        if(has(props.member, "associations"))setAssociations(props.member.associations.items);
    }, [props.member.associations]);
    
    const parents =  filter(associations, function(assoc) { return includes(["FATHER", "MOTHER", "STEPMOTHER", "STEPFATHER"], assoc.type); });
    const sibspou =  filter(associations, function(assoc) { return includes(["SIBLING", "SPOUSE", "EXSPOUSE"], assoc.type); });
    const childs =  filter(associations, function(assoc) { return includes(["CHILD", "STEPCHILD"], assoc.type); });
    
    console.log("MEMBER", props.member)
    if(!associations) return null;
    return <Grid container style={{backgroundColor:'white', borderRadius:8, padding:10, marginBottom:20}}>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5" component="h5" style={{marginBottom:10}}>
                         Parents
                    </Typography>
                    {parents.map(assoc=><AssociationCard association={assoc}  />)}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5" component="h5"  style={{marginBottom:10}}>
                         Siblings & Spouses
                    </Typography>
                    {sibspou.map(assoc=><AssociationCard association={assoc} />)}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5" component="h5"  style={{marginBottom:10}}>
                         Children
                    </Typography>
                    {childs.map(assoc=><AssociationCard association={assoc} />)}
                </Grid>
            </Grid>
            
}