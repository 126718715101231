import React, {useEffect, useState} from 'react';
import {Paper, Grid, Typography, Container} from '@material-ui/core'
import {has, filter, includes} from 'lodash';
import IS from '../ImageService'

export default function ImageCard(props){
    const [image, setImage]= useState(null);
    
    useEffect(() => {
        async function getImage(){
            if(has(props.media, "assetKey"))setImage(await IS(props.media.assetKey))
        }
        getImage();
    }, [props.media]);
    

    if(!props.media) return null;
    return <img src={image} />
    
    
    
            
}