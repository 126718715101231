import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Delete from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import IS from '../ImageService'
import {Link as RouterLink, useHistory} from 'react-router-dom'
import {buildShortName} from '../../Utils/uihelpers'
import RemoveAssociationDialog from './RemoveAssociationDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight:100,
    display: "inline-block",
    minWidth:320
  },
  actionarea:{
    display: 'flex',
    flexDirection:'row',
    justifyContent:'flex-start'
  },
  header:{
    fontSize:"16px !important"
  },
  media: {
    height: 100,
    width:120
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function AssociationCard({association, editMode=false, onRemove=null}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const history = useHistory();
  
  useEffect(() => {
    async function getProfile(){
      try{
          setProfileImage(await IS(association.member2.profileKey))
      } catch(e){
      console.log(e)
    }
        }
    
        
        getProfile()
    
      }, [association]);
  
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  const handleDelete =()=>{
    
  }
  
  const handleClick = ()=>{
    if(!editMode)history.push("/member/"+ association.member2.id);
  }
  const member = association.member2;
  if(!member) return null;
  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.actionarea} onClick={handleClick}>
      <CardMedia
        className={classes.media}
        image={profileImage}
      />
      <CardContent>
        <div style={{fontSize:16, fontWeight:'bold'}}>
        {buildShortName(member)}
        </div>
        <div>
        {association.type}
        </div>
      </CardContent>
      
      {editMode && <RemoveAssociationDialog association={association} onRemove={onRemove} />}
      </CardActionArea>
    </Card>
  );
}
