import React from 'react'
import {Typography, Container, Paper} from '@material-ui/core'
/* Display rich text about this person */
/* text will be editable via a rich text editor (web version of a word processor) */

function Description(props){
    
    return  <Container component={Paper} raised style={{marginBottom:20, minHeight:200, padding:20}}>
    <Typography variant="h5" component="h5">
                             Description
                            </Typography>
        <Typography component="p" style={{whiteSpace: 'pre-line'}}>
            {props.member.description || ""}
        </Typography>
    </Container>
}

export default Description;