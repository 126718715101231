import { Storage } from 'aws-amplify';

const man = "https://futurefamilymedia205301-dev.s3.us-east-2.amazonaws.com/siteassets/man.jpg"
const woman = "https://futurefamilymedia205301-dev.s3.us-east-2.amazonaws.com/siteassets/woman.jpg"
const other = "https://futurefamilymedia205301-dev.s3.us-east-2.amazonaws.com/siteassets/generic-profile-icon-8.jpg";


export default async(key, sex=null)=>{
    if(key) return await Storage.get(key)
    if(!key && sex){
        if(sex === "MALE") return man;
        if(sex === "FEMALE") return woman;
    };
    return other;
}