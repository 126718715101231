/* This will be a dynamic person viewer. 
    The person displayed is based on the id indicated in the url path
    The person data is passed on to the children compoents (headerblock, description, etc)
        this allows all information about each person to be dynamically displayed.
*/

import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import HeaderBlock from '../Components/Members/HeaderBlock';
import Description from '../Components/Members/Description';
import PersonSubNav from '../Components/Members/PersonSubNav';
import MemberAssociations from '../Components/Members/MemberAssociations';
import Media from '../Components/Members/Media';
import Family from '../Components/Family';
import {Link} from "react-router-dom";
import {find} from 'lodash';
import * as queries from '../graphql/savedQueries';
import { API } from 'aws-amplify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Person (props) {
      const [member, setMember] = useState({});
      const classes = useStyles();
  const [open, setOpen] = React.useState(false);
      useEffect(() => {
          setOpen(true)
        getMember();
      }, [props.match.params.id]);
        
      const getMember = async ()=> {
            const member = await API.graphql({ query: queries.getMember, variables: { id:  props.match.params.id} });
            console.log(member); // result: { "data": { "listTodos": { "items": [/* ..... */] } } }
            setMember(member.data.getMember)
            setOpen(false)
        }
    
    if(member === {}) return null;
  
        return <div  className="page">
            <PersonSubNav member={member} />
            <HeaderBlock member={member} />
            <Description member={member} />
            <MemberAssociations member={member} />
            <Media member={member} />
            <Backdrop className={classes.backdrop} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
        </div>;
        
  
}



export default withRouter(Person)