import React from 'react'

/* 
    Each person will be associated with 1 or more families
    For each family association, this component will duplicate itself and display
    the relative information
*/

function Family(props){
    
    return  <div class="flex-container">
        <p class="H1">Childhood Family</p>
      <p class="family"> His Father Henry Thiessen and mother Selena Derry were
        married 14 SEP 1875 at San Jose, Santa Clara, California, USA. </p>
      <p><a href="Thiessen_Henry_I60.html" title="Thiessen_Henry_I60.html">His
          father is Henry Thiessen <br/>
        </a></p>
      <p><a href="Derry_Selena_I39.html" title="Derry_Selena_I39.html">His
          mother is Selena Derry <br/>
        </a></p>
      <p><a href="Thiessen_Raymond_I189.html" title="Thiessen_Raymond_I189.html">His
          sibling is Raymond Lee Thiessen </a> born to mother and father</p>
      <p><a href="Thiessen_George_I170.html" title="Thiessen_George_I170.html">He
          is George Wesley Thiessen </a> born to mother and father</p>
      <p><a href="Thiessen_Anna_I175.html" title="Thiessen_Anna_I175.html">His
          sibling is Anna M Thiessen </a> born to mother and father</p>
      <p><a href="Thiessen_Minerva_I177.html" title="Thiessen_Minerva_I177.html">His
          sibling is Minerva Derry Thiessen </a> born to mother and father</p>
      <br/>
      </div>
}

export default Family;