import React, {useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Chip from '@material-ui/core/Chip';
import SearchIcon from '@material-ui/icons/Search';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExitToApp from '@material-ui/icons/ExitToApp';
import MailIcon from '@material-ui/icons/Mail';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import {
  Add,
  Help
  } from '@material-ui/icons';
import './Navigation.css';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  button:{
    margin: theme.spacing(1),
  },
  appBar: {
    zIndex: "1200 !important" ,
    height:54,
    backgroundColor:'#7b4777',
    justifyContent:'space-between'
  },
  toolBar:{
    height:54,
    minHeight:54
  },
  logOut:{
    marginLeft:10,
    borderRadius:8
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex:"1000 !important"
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  user: {
    display: 'none',
    marginLeft:10,
    borderRadius:8,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  title: {
    flexGrow: 1,
    display: 'block',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  logo:{
    height:40,
    marginRight: 20,
    border: '1px solid grey',
    borderRadius: 8,
    flexGrow: 1,
  }
}));

export default function  Navigation(props){
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [user, setUser] = useState(null)
    useEffect(() => {
        async function getUser(){
        const user = await Auth.currentAuthenticatedUser();
        console.log(user)
        setUser(user)
        }
        getUser()
      }, []);
    const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen)
    };
    
    const signOut = ()=>{
      Auth.signOut();
    }
    console.log("PROPS", props)
    return <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <RouterLink to="/"><img src="https://futurefamilymedia205301-dev.s3.us-east-2.amazonaws.com/siteassets/logo.jpg" className={classes.logo} /></RouterLink>
          
      <Typography className={classes.title} variant="h6" noWrap>
          Kin Info
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<Help />}
            component={RouterLink}
            to="/help"
          >
            Help
          </Button>
            
        </Toolbar>
      </AppBar>
    </div>
    
}