import React from 'react';
import {
    Button, 
    TextField, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup
} from '@material-ui/core';
import { API, graphqlOperation, Storage, Auth  } from 'aws-amplify';
import { inviteUser }  from '../../graphql/mutations';
import {has} from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import {Share} from '@material-ui/icons'
import { useSnackbar } from 'notistack';


export default function InviteDialog({member}) {
  const [open, setOpen] = React.useState(false);
  const [MFA, setMFA] = React.useState('EMAIL')
  const { enqueueSnackbar } = useSnackbar();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleInviteAndClose = async() => {
    
      //Create Association
      try {
        var user = {id:member.id}
        if(has(member, 'email') && member.email.length > 0) user.email = member.email.toLowerCase();
        if(has(member, 'phone') && member.phone.length > 0) user.phone = "+1"+member.phone.replace(/\D/g, "");
        user.MFA = MFA;
        const data = await API.graphql(graphqlOperation(inviteUser, user))
        enqueueSnackbar(`Invitation SENT`, { variant: 'success' });
        setOpen(false);
    
      } catch(e){
        console.log(e)
        enqueueSnackbar(`Invitation Failed`, { variant: 'error' });
      }
  };
  return (
    <div>
      <Button  color="primary" variant="contained" onClick={handleClickOpen} startIcon={<Share />}>
        Invite
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Invite {member.name.firstname}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the way you would like to invite {member.name.firstname}:
          </DialogContentText>
          <br/>
          <ButtonGroup fullWidth>
          {member.email && <Button onClick={()=>setMFA('EMAIL')} color="primary" variant={MFA==="EMAIL"?"contained":"outlined"}>
            Email
          </Button>}
          {member.phone && <Button onClick={()=>setMFA('SMS')} color="primary" variant={MFA==="SMS"?"contained":"outlined"}>
            Phone
          </Button>}
          </ButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleInviteAndClose} color="primary">
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
